.loading-spinner {
  border-width: 0.5rem;
  height: 4rem;
  width: 4rem;
}

.nav-custom-dropdown {
  font-size: 1.2rem;
}

.table {
  vertical-align: middle;
}

.select-is-invalid > div:not(:focus-within) {
  border-color: #dc3545;
}

.input-group .form-control.is-invalid {
  z-index: inherit;
}

.custom-option {
  transition: background 10ms;
}

.custom-option:hover:not(.custom-select__option--is-selected) {
  transition-delay: 10ms;
  background: #deebff;
}

.custom-option.custom-select__option--is-focused:not(:hover) {
  background: none;
}

.custom-option.custom-select__option--is-selected:not(:hover) {
  background: #2684ff;
}
